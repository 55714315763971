import gql from "graphql-tag";

export const EXCHANGE_POINT_STATUS = gql`
  mutation($status: ExchangePointStatus!, $exchangePointId: String!) {
    exchangePointChangeStatus(
      status: $status
      exchangePointId: $exchangePointId
    )
  }
`;
