import { styled } from "baseui";
import { Button } from "baseui/button";
import React, { FC, memo } from "react";

export const AlertContainer = styled("div", ($theme) => ({
  display: "block",
  backgroundColor: "#000",
  position: "absolute",
  transform: "translateZ(0)",
  width: "100%",
  zIndex: "10",
  height: "100%",
}));

export const AlertCard = styled("div", ($theme) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  boderRadius: "4px",
  position: "absolute",
  top: "25%",
  left: "50%",
  marginLeft: "-170px",
  backgroundColor: "#f9f9f9",
  transform: "translateZ(0)",
  minWidth: "300px",
  minHeight: "200px",
  padding: "16px",
  zIndex: "10",
  maxWidth: "350px",
  boxShadow: "0px 2px 2px 1px #b7b7b7",
}));

export const AlertTitle = styled("div", ({ $theme }) => ({
  color: $theme.colors.textDark,
  textAlign: "center",
  fontSize: "1.2rem",
  fontWeight: "bold",
  borderBottom: "1px #dadada solid",
}));

export const ButtonCancel = styled(Button, ({ $theme }) => ({
  padding: "4px 8px",
  paddingTop: "5px !important",
  paddingBottom: "5px !important",
  backgroundColor: "#d8d8d8",
  color: "#000",
  marginRight: "16px",
  ":hover": {
    backgroundColor: "#b7b7b7",
  },
}));

export const ButtonConfirm = styled(Button, ({ $theme }) => ({
  marginLeft: "16px",
  padding: "4px 8px",
  paddingTop: "5px !important",
  paddingBottom: "5px !important",
}));

export const AlertText = styled("div", ({ $theme }) => ({
  marginTop: "16px",
}));

export const AlertAction = styled("div", ({ $theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

const Container = styled("div", () => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "16px",
  width: "50%",
  padding: "5%",
  position: "relative",
}));

const Dots = styled("div", (props) => ({
  display: "inline-block",
  background: props.$theme.colors.primary,
  height: "5px",
  width: "5px",
  opacity: 0,
  borderRadius: "50%",
  transform: "translateX(-180px)",
  margin: "0 2px 0 2px",
  animationDuration: "4s",
  animationIterationCount: "infinite",
  animationName: {
    from: {
      transform: "translateX(0px)",
      opacity: "0.8",
    },
    to: {
      transform: "translateX(180px)",
      opacity: "0",
    },
  },
  animationDelay: props.delay && props.delay,
}));

export interface InLineLoaderProps {
  color?: string;
}

const Dot = (delay) => {
  return <Dots delay={delay} />;
};

export const InLineLoader: FC<InLineLoaderProps> = memo((props) => {
  const { color = "#4092de" } = props;

  return (
    <Container color={color}>
      <Dot delay=".8s" />
      <Dot delay=".7s" />
      <Dot delay=".6s" />
      <Dot delay=".5s" />
      <Dot delay=".4s" />
      <Dot delay=".3s" />
      <Dot delay=".2s" />
      <Dot delay=".1s" />
    </Container>
  );
});
