export const HANBAITEN = 'hanbaiten';
export const KOJITEN = 'kojiten';

// function urlReducer(projectName){
//   switch(process.env.REACT_APP_PROJECT){
//     case PROJECT.tokyo:
//       return `http://tokyo-test.web-mirai.jp/authorizer?serviceId=login&account=`;
//     case PROJECT.chugoku:
//       return `http://chugoku-test.web-mirai.jp/authorizer?serviceId=login&account=`;
//     case PROJECT.kyushu:
//       return `http://kyushu-test.web-mirai.jp/authorizer?serviceId=login&account=`;
//   }
// }

export const exportExcelUrlLogin = `http://${process.env.REACT_APP_PROJECT}-test.web-mirai.jp/authorizer?serviceId=login&account=`;

export const PROJECT = {
  tokyo: 'tokyo',
  chugoku: 'chugoku',
  tohoku: 'tohoku',
  mirai: 'mirai',
  hokaido: 'hokaido',
};

export const isTokyo =
  process.env.REACT_APP_PROJECT === PROJECT.tokyo
  || process.env.REACT_APP_PROJECT === PROJECT.hokaido
  ? true : false;

export const dataFilterBranch =
  process.env.REACT_APP_PROJECT === PROJECT.tokyo ||
  process.env.REACT_APP_PROJECT === PROJECT.hokaido
    ? [
        {
          code: "",
          branchName: "すべての支店",
        },
        {
          code: "A510",
          branchName: "北海道支店",
        },
        {
          code: "B110",
          branchName: "北東北支店",
        },
        {
          code: "B120",
          branchName: "秋田支店",
        },
        {
          code: "B210",
          branchName: "青森支店",
        },
        {
          code: "C110",
          branchName: "東北支店",
        },
        {
          code: "C120",
          branchName: "山形営業所",
        },
        {
          code: "C210",
          branchName: "福島支店",
        },
        {
          code: "D110",
          branchName: "埼玉支店",
        },
        {
          code: "D310",
          branchName: "栃木支店",
        },
        {
          code: "D410",
          branchName: "群馬支店",
        },
        {
          code: "E110",
          branchName: "多摩支店",
        },
        {
          code: "E210",
          branchName: "山梨支店",
        },
        {
          code: "E310",
          branchName: "長野支店",
        },
        {
          code: "F110",
          branchName: "神奈川支店",
        },
        {
          code: "F210",
          branchName: "相模原支店",
        },
        {
          code: "F310",
          branchName: "川崎支店",
        },
        {
          code: "G110",
          branchName: "茨城支店",
        },
        {
          code: "G210",
          branchName: "千葉支店",
        },
        {
          code: "H110",
          branchName: "東京東支店",
        },
        {
          code: "H210",
          branchName: "東京中央支店",
        },
        {
          code: "H410",
          branchName: "東京住設事業部",
        },
        {
          code: "H510",
          branchName: "東京工具部",
        },
        {
          code: "I110",
          branchName: "静岡支店",
        },
        {
          code: "K110",
          branchName: "新潟支店",
        },
        {
          code: "K210",
          branchName: "北陸支店",
        },
        {
          code: "N110",
          branchName: "特需１部",
        },
        {
          code: "N310",
          branchName: "特需３部",
        },
        {
          code: "N410",
          branchName: "特需４部",
        },
      ]
    : [
        {
          code: "",
          branchName: "すべての支店",
        },
        {
          code: "B110",
          branchName: "北東北支店",
        },
        {
          code: "B120",
          branchName: "秋田営業所",
        },
        {
          code: "K210",
          branchName: "青森支店",
        },
        {
          code: "C110",
          branchName: "東北支店",
        },
        {
          code: "C120",
          branchName: "山形営業所",
        },
        {
          code: "C210",
          branchName: "福島支店",
        },
      ];
