import gql from "graphql-tag";

export const GET_EXCHANGE_POINT_HISTORY = gql`
  query exchangePointHistory(
    $status: ExchangePointStatus
    $userType: Role
    $searchText: String = ""
    $offset: Float = 0
    $limit: Float = 10
  ) {
    exchangePointHistory(
      status: $status
      userType: $userType
      searchText: $searchText
      offset: $offset
      limit: $limit
    ) {
      id
      user {
        id
        username
        userType
      }
      createdAt
      gifts {
        id
        type
        title
        point
        image
        quantity
      }
      status
      deliveryAddress
      memo
      contact {
        tel
        email
      }
    }
  }
`;
