import gql from 'graphql-tag';

export const CREATE_USERS = gql`
  mutation createUsers($users: [CreateUserInput!]!, $forImport: Boolean) {
    createUsers(users: $users, forImport: $forImport) {
      id
      pwd
      username
      userType
      nameKanji
      tel
      nameKana
      jobTitle
      gender
      visitorFlagDay1
      visitorFlagDay2
      addresses {
        id
        address
        default
        lastUsed
      }
      sellerId
      mailAddr
      rank
      status
      hatTeamCode
      hatTokuCode
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: String!, $user: UpdateUserInput!) {
    updateUser(id: $id, user: $user) {
      id
      point
      username
      userType
      nameKanji
      tel
      nameKana
      sellerId
      jobTitle
      sellerId1
      sellerId2
      sellerId3
      sellerId4
      mailAddr
      companyNameKanji
      companyNameKana
      isReceiveMail
      # addresses {
      #   id
      #   address
      #   default
      #   lastUsed
      # }
      rank
      status
      hatTeamCode
      hatTokuCode
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id)
  }
`;

export const CHANGE_GIFT = gql`
  mutation gift($userId: String!, $gift: ExchangeGifts!, $point: Float!) {
    exchangeGift(
      exchangeGift: { userId: $userId, exchangeGift: $gift, point: $point }
    )
  }
`;
export const CREATE_ADDRESS = gql`
  mutation ($address: String!, $default: Boolean) {
    createAddress(address: { address: $address, default: $default }) {
      id
      address
      default
      lastUsed
    }
  }
`;

export const CANCEL_TRADE = gql`
  mutation cancelPoint($id: String!) {
    cancelPoint(id: $id)
  }
`;

export const UPDATE_USER_HANBAITEN = gql`
  mutation updateSendMail($isSend: Boolean!, $userId: String!) {
    updateSendMail(isSend: $isSend, userId: $userId) {
      id
      sendMail
    }
  }
`;

export const SEND_CREDENTIAL = gql`
  mutation ($userInput: [UserCredentialInput!]!) {
    sendCredentialsToUserByEmail(userCredentials: $userInput)
  }
`;
