import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

import common from "./common";
import users from "./users";

const reducers = {
    common,
    users,
}

const store = createStore(
    combineReducers(reducers),
    applyMiddleware(thunk, logger)
);

export default store;