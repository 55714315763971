import gql from 'graphql-tag'

export const GET_COMPANY_AND_COUNT = gql`
  query {
    companyAndCount {
      count
      companies {
        id
        name
        address
      }
    }
  }
`

export const GET_TEAM_CODE = gql`
  query($userType: UserType) {
    hatTeamCodes(userType: $userType)
  }
`
export const GET_COMPANY_BY_TEAMCODE = gql`
  query($teamCode: String!) {
    hatTokuCodesAndCompanies(teamCode: $teamCode) {
      hatTokuCode
      companyName
    }
  }
`
export const GET_KEY_MAN = gql`
  query($tokuCode: String) {
    hanbaitensWithKeyMan(tokuCode: $tokuCode) {
      id
      keymanName
      keymanCode
    }
  }
`
