import { useMutation, useQuery } from '@apollo/react-hooks'
import { withStyle } from 'baseui'
import { StatefulSelect } from 'baseui/select'
import numeral from 'numeral'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Alert from '../../components/Alert/Alert'
import Button from '../../components/Button/Button'
import { Col as Column, Row as Rows } from '../../components/FlexBox/FlexBox'
import Input from '../../components/Input/Input'
import NoResult from '../../components/NoResult/NoResult'
import openNotification, {
  typeNotificaton,
} from '../../components/openNotification/openNotification'
import Select from '../../components/Select/Select'
import { Header, Heading, Wrapper } from '../../components/WrapperStyle'
import { AuthContext, Role } from '../../context/auth'
import { useDrawerDispatch } from '../../context/DrawerContext'
import { EXCHANGE_POINT_STATUS } from '../../graphql/mutation/point.mutation'
import { GET_EXCHANGE_POINT_HISTORY } from '../../graphql/query/point.query'
import {
  StyledCell,
  StyledHead,
  StyledHeadCell,
  StyledRow,
  StyledTable,
  StyledTBody,
} from '../Category/Category.style'
import { PointExchangeStatus, UserType } from './../../models/index'

// type CustomThemeT = { red400: string; textNormal: string; colors: any };
// const themedUseStyletron = createThemedUseStyletron<CustomThemeT>();

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0,
    },
  },
}))

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}))

const userTypeOptions = [
  { value: 'All', label: 'すべて' },
  {
    value: UserType.hanbaiten,
    label: '販売店',
  },
  { value: UserType.kojiten, label: '工事店' },
]

const statusSelectOptionsAll = [
  { value: 'すべて', label: 'すべて' },
  { value: PointExchangeStatus.exchanged, label: '交換依頼中' },
  { value: PointExchangeStatus.confirmed, label: '注文完了' },
  { value: PointExchangeStatus.delivered, label: '交換完了' },
]

const statusSelectOptions = [
  { value: PointExchangeStatus.exchanged, label: '交換依頼中' },
  { value: PointExchangeStatus.confirmed, label: '注文完了' },
  { value: PointExchangeStatus.delivered, label: '交換完了' },
]

export default function PointExchange() {
  const { role } = useContext(AuthContext)
  const { register } = useForm()
  const dispatch = useDrawerDispatch()

  const { data, refetch, fetchMore } = useQuery(GET_EXCHANGE_POINT_HISTORY)

  const [changeStatus] = useMutation(EXCHANGE_POINT_STATUS)

  const [status, setStatus] = useState([])
  const [limit, setLimit] = useState([])
  const [search, setSearch] = useState(null)
  const [selected, setSelected] = useState(null)

  const [loadingMore, toggleLoading] = useState(false)
  const [showLoadMore, setShowLoadMore] = useState(false)

  const [loadingStatus, setLoadingStatus] = useState(false)
  const [loadingOptionUserType, setLoadingOptionUserType] = useState(false)

  const [isAlertOpen, setAlertOpen] = useState(false)
  const [alertTitle, setAlertTitle] = useState('')
  const [alertLoadding, setAlertLoading] = useState(false)

  const [isEventStatusExchangePoint, setIsEventStatusExchangePoint] = useState()
  const [isRowStatusExchangePoint, setIsRowStatusExchangePoint] = useState()
  const [currentSelectUserType, setCurrentSelectUserType] = useState('')

  useEffect(() => {
    if (data) {
      setShowLoadMore(true)
    }
  }, [data])

  useEffect(() => {
    register({ name: 'status' })
  }, [register])

  const openDrawer = useCallback(
    (dData) =>
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'POINT_EXCHANGE_DETAIL',
        data: {
          exchangePoint: dData,
          closeOpenDrawer,
        },
      }),
    [dispatch]
  )

  function handleStatusFilter({ value }) {
    setStatus(value)
    setLoadingStatus(true)
    refetch({
      limit: limit[0]?.value ? limit[0].value : 10,
      status: value[0]?.value !== 'すべて' ? value[0].value : null,
    })
      .then((t) => setLoadingStatus(false))
      .catch((t) => setLoadingStatus(true))
  }

  const onhandleStatus = (e, row) => {
    setIsEventStatusExchangePoint(e)
    setIsRowStatusExchangePoint(row)
    setAlertOpen(true)

    let currentStatus =
      row.status === PointExchangeStatus.exchanged
        ? '交換依頼中'
        : row.status === PointExchangeStatus.confirmed
        ? '注文完了'
        : row.status === PointExchangeStatus.delivered
        ? '交換完了'
        : ''

    let newStatus =
      e.value[0].value === PointExchangeStatus.exchanged
        ? '交換依頼中'
        : e.value[0].value === PointExchangeStatus.confirmed
        ? '注文完了'
        : e.value[0].value === PointExchangeStatus.delivered
        ? '交換完了'
        : ''

    setAlertTitle(
      '状態を「' + currentStatus + '」から「' + newStatus + '」に変更する'
    )
  }

  const handleStatus = (e, row) => {
    try {
      if (e.value[0].value === row.status) {
        setAlertOpen(false)
        return
      }
      setAlertLoading(true)

      changeStatus({
        variables: { exchangePointId: row.id, status: e.value[0].value },
      })
        .then(() => {
          setAlertLoading(false)
          setAlertOpen(false)
          refetch()
          openNotification(typeNotificaton.success, '')
        })
        .catch((err) => {
          openNotification(
            typeNotificaton.error,
            `${err.toString().split(' ').slice(2).join('')}`
          )
          setAlertLoading(false)
          setAlertOpen(false)
        })
    } catch (err) {
      openNotification(
        typeNotificaton.error,
        `${err.toString().split(' ').slice(2).join('')}`
      )
    }
  }

  function handleSearch(event) {
    const { value } = event.currentTarget
    setSearch(value)
    if (value.length > 0) {
      refetch({
        searchText: value,
      })
      setShowLoadMore(true)
    } else {
      setShowLoadMore(true)
    }
  }

  const handleDetail = (event, index) => {
    let dData = data?.exchangePointHistory[index]
    openDrawer(dData)
    setSelected(index)
  }

  const handleUserTypeOption = ({ value }) => {
    setCurrentSelectUserType(value) // value[0].value
    setLoadingOptionUserType(true)
    if (value.length > 0) {
      refetch({
        userType:
          value[0].value === UserType.hanbaiten
            ? UserType.hanbaiten
            : value[0].value === UserType.kojiten
            ? UserType.kojiten
            : null,
      })
        .then(() => {
          setTimeout(() => {
            setLoadingOptionUserType(false)
          }, 300)
        })
        .catch((err) => {
          openNotification(
            typeNotificaton.error,
            `${err.toString().split(' ').slice(2).join('')}`
          )
          setLoadingOptionUserType(false)
        })
    }
  }

  const closeOpenDrawer = () => {
    refetch()
  }

  function loadMore() {
    toggleLoading(true)
    fetchMore({
      variables: {
        offset: data.exchangePointHistory.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        toggleLoading(false)
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          exchangePointHistory: [
            ...prev.exchangePointHistory,
            ...fetchMoreResult.exchangePointHistory,
          ],
        })
      },
    })
      .then(({ data }) => {
        if (data.exchangePointHistory.length === 0) {
          setShowLoadMore(false)
        }
      })
      .catch((err) => {
        openNotification(
          typeNotificaton.error,
          `${err.toString().split(' ').slice(2).join('')}`
        )
      })
  }

  return (
    <>
      <Alert
        isOpen={isAlertOpen}
        onCancel={() => {
          setAlertOpen(false)
        }}
        title={'確認'}
        content={alertTitle}
        confirm={true}
        onConfirm={() =>
          handleStatus(isEventStatusExchangePoint, isRowStatusExchangePoint)
        }
        loadding={alertLoadding}
      />
      <Header
        style={{
          marginBottom: 15,
        }}
      >
        <Col md={3} xs={12}>
          <Heading>ポイント交換</Heading>
        </Col>

        <Col md={9} xs={12}>
          <Row>
            {role === Role.admin ? (
              <Col md={3} xs={12}>
                <Select
                  options={userTypeOptions}
                  labelKey='label'
                  valueKey='value'
                  clearable={false}
                  searchable={false}
                  isLoading={loadingOptionUserType}
                  value={currentSelectUserType}
                  onChange={handleUserTypeOption}
                  placeholder='種別を選択'
                />
              </Col>
            ) : (
              ''
            )}
            <Col md={3} xs={12}>
              <Select
                options={statusSelectOptionsAll}
                searchable={false}
                labelKey='label'
                valueKey='value'
                placeholder='状況'
                isLoading={loadingStatus}
                value={status}
                clearable={false}
                onChange={handleStatusFilter}
              />
            </Col>
            <Col md={3} xs={12}>
              <Input
                value={search}
                placeholder='キーワードで探す'
                onChange={handleSearch}
                clearable
                type='text'
              />
            </Col>
          </Row>
        </Col>
      </Header>

      <Wrapper
        style={{
          boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          // height: "75vh",
          width: '100%',
          boxSizing: 'border-box',
          overflow: 'hidden',
        }}
      >
        <StyledTable>
          <StyledHead style={{ display: 'block' }}>
            <StyledHeadCell style={{ minWidth: '100px', width: '11%' }}>
              ユーザ名
            </StyledHeadCell>
            <StyledHeadCell style={{ minWidth: '100px', width: '11%' }}>
              種別
            </StyledHeadCell>
            <StyledHeadCell style={{ minWidth: '100px', width: '11%' }}>
              お届け先
            </StyledHeadCell>
            <StyledHeadCell style={{ minWidth: '100px', width: '11%' }}>
              携帯電話番号
            </StyledHeadCell>
            <StyledHeadCell style={{ minWidth: '100px', width: '11%' }}>
              メールアドレス
            </StyledHeadCell>

            <StyledHeadCell style={{ minWidth: '100px', width: '11%' }}>
              交換日
            </StyledHeadCell>
            <StyledHeadCell style={{ minWidth: '100px', width: '11%' }}>
              説明
            </StyledHeadCell>
            <StyledHeadCell style={{ minWidth: '100px', width: '11%' }}>
              数量
            </StyledHeadCell>
            <StyledHeadCell
              style={{ zIndex: '1', minWidth: '180px', width: '1%' }}
            >
              ステータス
            </StyledHeadCell>
          </StyledHead>
          <StyledTBody
            style={{
              display: 'block',
              overflow: 'auto',
              maxHeight: showLoadMore
                ? 'calc(100vh - 245px)'
                : 'calc(100vh - 245px)',
              // maxHeight: "70vh",
              width: '100%',
            }}
          >
            {data?.exchangePointHistory ? (
              data?.exchangePointHistory?.length ? (
                data?.exchangePointHistory.map((row, index) => (
                  <React.Fragment key={index}>
                    <StyledRow>
                      <StyledCell
                        onClick={(event) => {
                          handleDetail(event, index)
                        }}
                        style={{
                          backgroundColor: `${
                            selected === index ? '#ECECEC' : ''
                          }`,
                          minWidth: '100px',
                          width: '11%',
                        }}
                      >
                        {row.user.username}
                      </StyledCell>
                      <StyledCell
                        onClick={(event) => {
                          handleDetail(event, index)
                        }}
                        style={{
                          backgroundColor: `${
                            selected === index ? '#ECECEC' : ''
                          }`,
                          minWidth: '100px',
                          width: '11%',
                        }}
                      >
                        {row?.user.userType === UserType.kojiten
                          ? '工事店'
                          : row?.user.userType === UserType.hanbaiten
                          ? '販売店'
                          : ''}
                      </StyledCell>
                      <StyledCell
                        onClick={(event) => {
                          handleDetail(event, index)
                        }}
                        style={{
                          backgroundColor: `${
                            selected === index ? '#ECECEC' : ''
                          }`,
                          width: '11%',
                        }}
                      >
                        {row.deliveryAddress}
                      </StyledCell>
                      <StyledCell
                        onClick={(event) => {
                          handleDetail(event, index)
                        }}
                        style={{
                          backgroundColor: `${
                            selected === index ? '#ECECEC' : ''
                          }`,
                          width: '11%',
                        }}
                      >
                        {row?.contact?.tel}
                      </StyledCell>
                      <StyledCell
                        onClick={(event) => {
                          handleDetail(event, index)
                        }}
                        style={{
                          backgroundColor: `${
                            selected === index ? '#ECECEC' : ''
                          }`,
                          maxWidth: '111px',
                          width: '11%',
                        }}
                      >
                        {row?.contact?.email}
                      </StyledCell>

                      <StyledCell
                        onClick={(event) => {
                          handleDetail(event, index)
                        }}
                        style={{
                          backgroundColor: `${
                            selected === index ? '#ECECEC' : ''
                          }`,
                          width: '11%',
                          textAlign: 'right',
                        }}
                      >
                        {new Date(parseFloat(row?.createdAt))
                          .toLocaleDateString('ja')
                          .toString()}
                      </StyledCell>
                      <StyledCell
                        onClick={(event) => {
                          handleDetail(event, index)
                        }}
                        style={{
                          backgroundColor: `${
                            selected === index ? '#ECECEC' : ''
                          }`,
                          width: '11%',
                          maxWidth: '100px',
                          textAlign: 'right',
                        }}
                      >
                        {row?.memo}
                      </StyledCell>
                      <StyledCell
                        onClick={(event) => {
                          handleDetail(event, index)
                        }}
                        style={{
                          backgroundColor: `${
                            selected === index ? '#ECECEC' : ''
                          }`,
                          width: '11%',
                          textAlign: 'right',
                        }}
                      >
                        {numeral(row?.gifts.length).format('0,0.[00]')}
                      </StyledCell>
                      <StyledCell
                        style={{
                          minWidth: '180px',
                          width: '1%',
                        }}
                      >
                        <StatefulSelect
                          options={statusSelectOptions}
                          labelKey='label'
                          valueKey='value'
                          searchable={false}
                          clearable={false}
                          placeholder={
                            row.status === PointExchangeStatus.exchanged
                              ? '注文完了'
                              : row.status === PointExchangeStatus.confirmed
                              ? '注文完了'
                              : row.status === PointExchangeStatus.delivered
                              ? '交換完了'
                              : ''
                          }
                          disabled={
                            row.status === PointExchangeStatus.delivered
                              ? true
                              : false
                          }
                          onChange={(e) => onhandleStatus(e, row)}
                          overrides={{
                            Placeholder: {
                              style: ({ $theme }) => {
                                return {
                                  ...$theme.typography.fontBold14,
                                  color: $theme.colors.textNormal,
                                }
                              },
                            },
                            DropdownListItem: {
                              style: ({ $theme }) => {
                                return {
                                  ...$theme.typography.fontBold14,
                                  color: $theme.colors.textNormal,
                                }
                              },
                            },
                            OptionContent: {
                              style: ({ $theme, $selected }) => {
                                return {
                                  ...$theme.typography.fontBold14,
                                  color: $selected
                                    ? $theme.colors.textDark
                                    : $theme.colors.textNormal,
                                }
                              },
                            },
                            SingleValue: {
                              style: ({ $theme }) => {
                                return {
                                  ...$theme.typography.fontBold14,
                                  color: $theme.colors.textNormal,
                                }
                              },
                            },
                            Popover: {
                              props: {
                                overrides: {
                                  Body: {
                                    style: {
                                      zIndex: 5,
                                      minWidth: 120,
                                    },
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </StyledCell>
                    </StyledRow>
                  </React.Fragment>
                ))
              ) : (
                <NoResult
                  hideButton={false}
                  style={{
                    gridColumnStart: '1',
                    gridColumnEnd: 'one',
                  }}
                />
              )
            ) : null}
          </StyledTBody>
        </StyledTable>
        {showLoadMore && data?.exchangePointHistory?.length ? (
          <Row
            style={{
              padding: 8,
              backgroundColor: '#eee',
              flexShrink: 0,
            }}
          >
            <Col
              md={12}
              style={{
                height: 40,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button onClick={loadMore} isLoading={loadingMore}>
                {`もっと見る`}
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </Wrapper>
    </>
  )
}
