import { IUserLogin, ICommon } from "../models";

const SET_USER_LOGIN = "SET_USER_LOGIN";
const SET_USER_ID_LOGIN = "SET_USER_ID_LOGIN";
const SET_SHOW_SIDE_BAR = "SET_SHOW_SIDE_BAR";

export const setUserLogin = (userLogin: IUserLogin) => (dispatch: any) => {
  dispatch({ type: SET_USER_LOGIN, userLogin });
};

export const setUserLoginId = (userLogin: String) => (dispatch: any) => {
  dispatch({ type: SET_USER_LOGIN, userLogin });
};

export const setShowSidebarCommon = (showSidebar: boolean) => (dispatch: any) => {
  dispatch({ type: SET_SHOW_SIDE_BAR, showSidebar });
};

const initialState: ICommon = {
  userLogin: [],
  userLoginId: "",
  showSidebar: true,
};

export default function CommonReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_USER_LOGIN:
      return {
        ...state,
        userLogin: action.userLogin,
      };
    case SET_USER_ID_LOGIN:
      return {
        ...state,
        userLoginId: action.userLoginId,
      };
    case SET_SHOW_SIDE_BAR:
      return {
        ...state,
        showSidebar: action.showSidebar,
      };
    default:
      return state;
  }
}
