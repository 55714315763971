import React from "react";
import NoResultSvg from "./no-result.svg";
import { NoResultWrapper, ImageWrapper, ButtonWrapper } from "./NoResult.style";
import Button from "../Button/Button";

type NoResultProps = {
  id?: string;
  onClick?: () => void;
  hideButton?: boolean;
  style?: any;
  priceSetting?: boolean;
};

const NoResult: React.FC<NoResultProps> = ({
  id,
  onClick,
  hideButton = true,
  style,
  priceSetting = false,
}) => {
  const isIE =
    navigator.userAgent.indexOf("MSIE ") > -1 ||
    navigator.userAgent.indexOf("Trident/") > -1;

  return (
    <NoResultWrapper id={id} style={style}>
      {priceSetting ? (
        <div>
          <h3>選ぶ Hanbai-ten!</h3>
        </div>
      ) : (
        <div>
          <h3>当該結果が見つかりませんでした。</h3>
        </div>
      )}
      <ImageWrapper>
        <img
          src={NoResultSvg}
          alt="No Result"
          style={{ marginLeft: !isIE ? "" : "-140px" }}
        />
      </ImageWrapper>
      {hideButton ? (
        <ButtonWrapper>
          <div onClick={onClick}>
            <Button type="button">後でもう一度やり直してください</Button>
          </div>
        </ButtonWrapper>
      ) : null}
    </NoResultWrapper>
  );
};

export default NoResult;
