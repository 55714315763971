import { styled } from 'baseui';
import { Grid as Grids, Row as Rows, Col as Cols } from 'react-flexbox-grid';

// export const Grid = styled(Grids, ({ $theme }) => ({
//   &.container,
//   &.container-fluid {
//     padding: 0 15px;
//   }
// }));

export const Grid = styled(Grids, () => ({
  // height: "100%",
}));

export const Row = styled(Rows, () => ({
  // margin: "0 -15px 16px",

  // ":last-child": {
  //   marginBottom: "0px",
  //   marginLeft: "0px",
  //   marginRight: "0px",
  // },
  // marginBottom: "0px",
  // marginLeft: "-15px",
  // marginRight: "-15px",
  marginBottom: '0px',
  // marginLeft: "0px",
  // marginRight: "0px",
}));

export const Col = styled(Cols, () => ({
  // padding: "0 15px",
}));

export const PSelleerId = styled(Cols, () => ({
  textAlign: 'center',
  marginTop: '1px',
  color: 'rgb(0, 197, 141)',
  fontWeight: 'bold',
}));
