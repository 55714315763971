import { IUsers } from "../models";

const initialState: IUsers = {
  user: [],
};

export default function UsersReducer(state = initialState, action: any) {
  switch (action.type) {
    default:
      return state;
  }
}
